
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import Container2fa from '@/components/views/auth/2fa/Container2fa.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
    Container2fa,
  },
  setup() {
    const phoneNumber = ref('')

    return {
      phoneNumber,
    }
  },
})
